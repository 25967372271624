.container {
  position: relative;
  --listing-card-carousel-min-width: auto;
  --listing-card-overflow: visible;
  --listing-card-scroll-snap-carousel-image-height: 186px;
  --listing-card-redesign-width: 279px;
  --listing-card-redesign-carousel-height: auto;

  @media (--screen-xl) {
    --listing-card-redesign-width: 100%;
  }
}

.container.redesignV2Container {
  position: relative;
  --listing-card-scroll-snap-carousel-image-height: 250px;
}
