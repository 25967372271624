@layer components {
  .mapToggle {
    position: var(--map-toggle-position);
    width: var(--map-toggle-width);
    height: var(--map-toggle-height);
    background: var(--color-white);
    box-shadow: var(--shadow-100-up);
    border-radius: var(--border-radius-full);
    font-size: var(--map-toggle-font-size);
    color: var(--color-gray-500);
    bottom: var(--map-toggle-bottom);
    right: var(--map-toggle-right);
    left: var(--map-toggle-left);
    top: var(--map-toggle-top);
    padding: var(--map-toggle-padding);
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
