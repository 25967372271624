.modal {
  --modal-padding: 0;
  --modal-panel-margin: var(--space-11x) 0 auto;
  --modal-panel-height: calc(100% - var(--space-11x));
  --modal-panel-display: flex;
  --modal-panel-border-radius: var(--space-9x) var(--space-9x) 0 0;
  --modal-panel-flex-direction: column;
  --modal-close-button-padding: var(--space-5x) var(--space-9x) var(--space-6x)
    var(--space-6x);
  --modal-close-button-color: var(--color-black);
  --modal-close-button-font-size: var(--font-size-xl);
  --map-toggle-right: var(--space-2-5x);

  @media (--screen-md) {
    --modal-panel-border-radius: var(--space-1x);
    --modal-panel-height: calc(100vh - 110px);
    --modal-panel-width: calc(100vw - 110px);
    --modal-close-button-color: var(--anchor-text-color-default);
    --modal-close-button-font-size: var(--font-size-2xl);
    --modal-close-button-padding: var(--space-4x);
  }
}

.mapContainer {
  flex: 1 1 calc(100% - var(--space-11x));
  width: 100%;
  display: flex;
  flex-direction: column;
}

.notBasicListingMapTogglePosition {
  --map-toggle-bottom: var(--space-28x);

  @media (--screen-md) {
    --map-toggle-bottom: var(--space-9x);
  }
}

.basicListingMapTogglePosition {
  --map-toggle-bottom: var(--space-9x);
}

.mapHeaderWrapper {
  display: flex;
  width: 100%;
  font-size: var(--font-size-body);

  @media (--screen-md) {
    min-height: var(--space-14x);
  }
}

.mapHeaderContent {
  background-color: var(--color-white);
  width: 100%;
  border-radius: var(--space-9x) var(--space-9x) 0 0;
  padding: 0 var(--space-6x);

  @media (--screen-md) {
    padding: 0 var(--space-5x);
  }

  .mapHeaderText {
    padding: var(--space-7x) var(--space-6x);
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
      font-size: var(--font-size-md);
      font-weight: var(--font-weight-semibold);
      line-height: var(--leading-tight);
      margin-bottom: var(--space-1x);
      text-align: center;
      color: var(--color-black);
      padding: 0 var(--space-6x);
    }

    .subheading {
      text-align: center;
      padding: 0 var(--space-6x);
      white-space: wrap;
    }

    @media (--screen-md) {
      flex-direction: row;
      padding: 0;
      height: 100%;

      .subheading {
        height: 100%;
        align-items: center;
        text-align: start;
        display: flex;
        padding: 0;
      }
    }
  }
}

.map {
  width: 100%;
  display: flex;
  flex: 1;

  @media (--screen-md) {
    height: 100%;
  }
}

.mapModalFooter {
  min-height: 72px;
  z-index: var(--z-index-1x);

  @media (--screen-md) {
    display: none;
  }
}
