.stickyFooter {
  --button-width: 100%;
  bottom: 0;
  left: 0;
  padding: var(--space-4x) var(--space-6x);
  width: 100%;
  background: var(--color-white);
  position: fixed;
  box-shadow: var(--shadow-100-up);
  z-index: var(--z-index-4x);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (--screen-md) {
    --icon-button-width: auto;
    --icon-button-max-width: 175px;
    background: var(--sticky-footer-background);
    transition: all;
    transition-duration: 300ms;
    transition-timing-function: linear;
    height: 80px;
    padding: var(--space-4x) var(--space-5x) var(--space-3x);
  }

  @media (--screen-xl) {
    display: none;
  }

  .footerCtas {
    @media (--screen-md) {
      margin-left: var(--space-3x);
      flex: 1;
      justify-content: flex-end;
      --icon-button-width: unset;
      --icon-button-max-width: auto;
      --icon-button-flex: unset;
    }
  }
}

@media (--screen-md) {
  .stickyFooterHidden {
    bottom: -80px;
  }
}

.favoriteButtonWrapper {
  display: none;
  --icon-cta-width: var(--space-12x);
  --icon-cta-height: var(--space-12x);
  --icon-cta-font-size: var(--font-size-2xl);
  --icon-cta-margin: 0;

  @media (--screen-md) {
    display: flex;
    margin-right: 15px;
  }
}

.propertyName {
  display: none;
  min-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-white);
  font-weight: bold;
  font-size: var(--font-size-2xl);
  flex: 1;

  @media (--screen-md) {
    display: block;
  }
}
