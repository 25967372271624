@layer components {
  .tooltip {
    --tooltip-caret-width: var(--space-5-5x);
    --tooltip-caret-half-width: calc(var(--tooltip-caret-width) / 2);
    --tooltip-caret-height: 9px;
    --tooltip-background-color: var(--color-gray-600);
    --tooltip-border-color: var(--color-white);
    --tooltip-min-width: 236px;
    --tooltip-max-width: 236px;
    --tooltip-max-content-width: 236px;
    --tooltip-max-content-width-with-close-button: 190px;
    position: absolute;
    max-width: var(--tooltip-max-width);
    min-width: var(--tooltip-min-width);
    padding: var(--space-2-5x) var(--space-3-5x);
    border-radius: var(--border-radius-2x);
    border: var(--space-px) solid var(--tooltip-border-color);
    background-color: var(--tooltip-background-color);
    color: var(--tooltip-text-color, var(--color-gray-200));
    z-index: var(--z-index-3x);
  }

  .tooltip::after,
  .tooltip::before {
    content: '';
    position: absolute;
    border-width: var(--tooltip-caret-height);
    border-style: solid;
  }

  .positionTop {
    left: var(--tooltip-left, 0);
    bottom: 100%;
    margin-bottom: var(--tooltip-caret-height);

    &::after,
    &::before {
      left: calc(var(--caret-x, '50%') - var(--tooltip-caret-half-width));
      top: 100%;
    }

    &::before {
      border-color: var(--tooltip-border-color) transparent transparent
        transparent;
    }

    &::after {
      margin-top: -2px;
      border-color: var(--tooltip-background-color) transparent transparent
        transparent;
    }
  }

  .positionBottom {
    left: var(--tooltip-left, 0);
    top: 100%;
    margin-top: var(--tooltip-caret-height);

    &::after,
    &::before {
      left: calc(var(--caret-x, '50%') - var(--tooltip-caret-half-width));
      bottom: 100%;
    }

    &::before {
      border-color: transparent transparent var(--tooltip-border-color)
        transparent;
    }

    &::after {
      margin-bottom: -2px;
      border-color: transparent transparent var(--tooltip-background-color)
        transparent;
    }
  }

  .positionLeft {
    top: var(--tooltip-top, 0);
    right: 100%;
    margin-right: var(--tooltip-caret-height);

    &::after,
    &::before {
      top: calc(var(--caret-y, '50%') - var(--tooltip-caret-half-width));
      left: 100%;
    }

    &::before {
      border-color: transparent transparent transparent
        var(--tooltip-border-color);
    }

    &::after {
      margin-left: -2px;
      border-color: transparent transparent transparent
        var(--tooltip-background-color);
    }
  }

  .positionRight {
    top: var(--tooltip-top, 0);
    left: 100%;
    margin-left: var(--tooltip-caret-height);

    &::after,
    &::before {
      top: calc(var(--caret-y, '50%') - var(--tooltip-caret-half-width));
      right: 100%;
    }

    &::before {
      border-color: transparent var(--tooltip-border-color) transparent
        transparent;
    }

    &::after {
      margin-right: -2px;
      border-color: transparent var(--tooltip-background-color) transparent
        transparent;
    }
  }
}

.tooltipTitle {
  color: var(--color-white);
  margin-bottom: var(--space-1-5x);
  font-weight: var(--font-weight-semibold);
}

.tooltipContent {
  max-width: var(--tooltip-max-content-width-with-close-button);

  @media (--screen-md) {
    max-width: var(--tooltip-max-content-width);
  }
}

.closeButton {
  font-size: var(--font-size-body);
  position: absolute;
  top: calc(var(--space-2-5x) * -1);
  right: calc(var(--space-3-5x) * -1);
  color: var(--tooltip-link-color);
  padding: var(--space-3x) var(--space-4x);
  margin: 7px 9px 0 0;
  -webkit-tap-highlight-color: transparent;

  @media (--screen-md) {
    display: none;
  }
}
