@layer components {
  .locationMatchBadgeWrapper {
    border-radius: var(--border-radius-2x);
    background-color: var(--color-white);
    padding: var(--space-1x) var(--space-1-5x);
    column-gap: var(--space-1x);
    display: flex;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    max-width: var(--space-48x);
    font-size: var(--font-size-sm);
    height: 22px;
    color: var(--color-gray-500);
    margin: 0 9px 9px 0;
  }

  .withUpdatedStyles.locationMatchBadgeWrapper {
    color: var(--color-white);
    background: none;
    padding: 0 var(--space-2x);
    font-size: 13px;
    height: unset;
    font-weight: var(--font-weight-semibold);
    margin: 0 9px var(--space-2-5x) 0;
    line-height: var(--leading-tight);

    @media (--screen-md) {
      margin: 0 0 var(--space-2-5x) 0;
    }
  }

  .locationSvg {
    font-size: var(--font-size-xs);
    color: var(--anchor-text-color-default);

    /* keeps svg from shrinking with badge size change */
    min-width: var(--space-3x);

    @media (--screen-md) {
      color: var(--color-gray-500);
    }
  }

  .withUpdatedStyles .locationSvg {
    color: var(--color-black);

    @media (--screen-md) {
      color: var(--color-white);
    }
  }

  /* --- Mobile Styles --- */

  .mobileButton {
    padding: 0 9px var(--space-2-5x) 0;
    --button-padding: 0 9px 9px 0;
    --button-font-size: var(--font-size-sm);
    --button-height: var(--space-9x);
    --button-text-color-base: var(--anchor-text-color-default);
    --button-placement: flex-start;
    --button-gap: 0 var(--space-1x);
  }

  .mobileButtonContent {
    background-color: var(--color-white);
    padding: var(--space-2x);
    gap: 0 var(--space-1x);
    border-radius: var(--border-radius-8x);
    font-size: var(--font-size-sm);
    color: var(--anchor-text-color-default);
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    max-width: var(--space-40x);
  }

  .truncateWrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--anchor-text-color-default);
    @media (--screen-md) {
      color: var(--color-gray-500);
    }
  }

  .withUpdatedStyles.mobileButton {
    background-color: transparent;
    --button-height: 35px;
    padding: 0 0 var(--space-2-5x) 0;
  }

  .withUpdatedStyles .truncateWrapper {
    color: var(--color-black);
    font-weight: var(--font-weight-medium);
    font-style: normal;
  }

  .withUpdatedStyles .mobileButtonContent {
    color: var(--color-white);
    border-radius: var(--border-radius-4x);
    font-size: 13px;
    height: 25px;
  }
}
