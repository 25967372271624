.ctaButtons {
  display: flex;
  width: 100%;
  gap: var(--space-3x);
  --icon-button-flex: 1;
  --button-font-size: var(--font-size-body);
  --button-outlined-background-color-hover: var(--color-white);

  @media (--screen-md) {
    --button-min-width: 165px;
  }
}
